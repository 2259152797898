@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}
.my-heading2 {
    @apply text-5xl text-center pt-12 mt-12 text-rose-300;
}
